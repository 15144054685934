"use strict";

var CmsPortada = function() {
    return {
        init: function () { 
            var containers = document.querySelectorAll('.draggable-zone');

            if (containers.length === 0) {
                return false;
            }

            var swappable = new Sortable.default(containers, {
                draggable: '.draggable',
                handle: '.draggable .draggable-handle',
                mirror: {
                    //appendTo: selector,
                    appendTo: 'body',
                    constrainDimensions: true
                }
            });

            swappable.on('sortable:stop', (event) => {
                var bloque = event.data.dragEvent.data.source.children[0].getAttribute('data-bloque');
                var orden = event.newIndex;
                var path = event.data.dragEvent.data.source.children[0].getAttribute('data-path');

                $.ajax({
                    url : path,
                    type : "GET",
                    data : {'bloque': bloque, 'orden': orden},
                    success : function() {
                        Notify.init("success", "Orden de portada actualizado");
                    }
                });
            });

            $("#estructura_portada a.bloque-add").each(function( index ) {
                var remote = $(this).data('load-remote') + '/' + index;
                $(this).data('load-remote', remote);
            });
        }
    };
}();

$(function() {
    CmsPortada.init();
});
