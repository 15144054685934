"use strict";

const PublicidadForm = function() {
    const addFormToCollection = function($collectionHolderClass) {
        let $collectionHolder = $('.' + $collectionHolderClass);
        let prototype = $collectionHolder.data('prototype');
        let index = $collectionHolder.data('index');
        let newForm = prototype;

        newForm = newForm.replace(/__name__/g, index);

        $collectionHolder.data('index', index + 1);

        let $newFormLi = $('<li class="row"><div class="col-sm-1 acciones"></div><div class="col-sm-11 html-creatividad"></div></li>');
        $newFormLi.find('.html-creatividad').append(newForm); 
        $collectionHolder.append($newFormLi);
       
        addFormDeleteLink($newFormLi);

    }

    const addFormDeleteLink = function($formLi) {
       
        let $removeFormButton = $('<button type="button" class="btn btn-danger btn-sm mt-8"><i class="ki ki-bold-close icon-sm p-0"></i></button>');
        $formLi.find('.acciones').append($removeFormButton); 
        $removeFormButton.on('click', function(e) {
            $formLi.remove();
        });
    }

    var inputColor = document.getElementById('colorPublicidad');
    var inputColorC = document.getElementById('colorPublicidadContextual');
    var inputPosicionC = document.getElementById('publicidad_contextual_posicion');
    var inputPosicion = document.getElementById('publicidad_posicion');

    var ocultar = function(el) {
        el.classList.add('d-none');
    }

    var mostrar = function(el) {
        el.classList.remove('d-none');
    }

    return {
		init: function (clase) {
            let $collectionHolder = $(clase);
            $collectionHolder.data('index', $collectionHolder.find('li').length);
            
            $collectionHolder.find('li').each(function() {
                addFormDeleteLink($(this));
            });

            $('body').on('click', '.add_creatividad_link', function(e) {
                var $collectionHolderClass = $(e.currentTarget).data('collectionHolderClass');
                addFormToCollection($collectionHolderClass);
            });


            var selection = $('#publicidad_posicion').next();
            if (inputColor) {
                var options = selection.find('.select2-selection__choice');
                var color = false;
                for (var i = 0; i < options.length; i++) {
                    options[i].title;
                    if(options[i].title == 'Rascacielos Derecha - Interior - Acompaña' || options[i].title == 'Rascacielos Izquierda - Interior - Acompaña' || options[i].title == 'Rascacielos Derecha Grande - Interior - Acompaña' || options[i].title == 'Rascacielos Izquierda Grande - Interior - Acompaña') {
                        color = true;
                    }
                }
                if (color == true) {
                    mostrar(inputColor);
                }
                else {
                    ocultar(inputColor);
                }
            }

            var selectionC = $('#publicidad_contextual_posicion').next();
            if (inputColorC) {
                var optionsC = selectionC.find('.select2-selection__choice');
                var colorC = false;
                for (var i = 0; i < optionsC.length; i++) {
                    optionsC[i].title;
                    if(optionsC[i].title == 'Rascacielos Derecha - Interior - Acompaña' || optionsC[i].title == 'Rascacielos Izquierda - Interior - Acompaña' || optionsC[i].title == 'Rascacielos Derecha Grande - Interior - Acompaña' || optionsC[i].title == 'Rascacielos Izquierda Grande - Interior - Acompaña') {
                        colorC = true;
                    }
                }
                if (colorC == true) {
                    mostrar(inputColorC);
                }
                else {
                    ocultar(inputColorC);
                }
            }

            if (inputPosicion) {
                inputPosicion.onchange = function(){
                    var selection = $('#publicidad_posicion').next();
                    var options = selection.find('.select2-selection__choice');
                    var color = false;
                    for (var i = 0; i < options.length; i++) {
                        if(options[i].title == 'Rascacielos Derecha - Interior - Acompaña' || options[i].title == 'Rascacielos Izquierda - Interior - Acompaña' || options[i].title == 'Rascacielos Derecha Grande - Interior - Acompaña' || options[i].title == 'Rascacielos Izquierda Grande - Interior - Acompaña') {
                             color = true;
                        }
                     }
                    if (color == true) {
                        mostrar(inputColor);
                    }
                    else {
                        ocultar(inputColor);
                    }
                };   
            }

            if (inputPosicionC) {
                inputPosicionC.onchange = function(){
                    var selection = $('#publicidad_contextual_posicion').next();
                    var optionsC = selection.find('.select2-selection__choice');
                    var color = false;
                    for (var i = 0; i < optionsC.length; i++) {
                        if(optionsC[i].title == 'Rascacielos Derecha - Interior - Acompaña' || optionsC[i].title == 'Rascacielos Izquierda - Interior - Acompaña' || optionsC[i].title == 'Rascacielos Derecha Grande - Interior - Acompaña' || optionsC[i].title == 'Rascacielos Izquierda Grande - Interior - Acompaña') {
                            color = true;
                        }
                    }
                    if (color == true) {
                        mostrar(inputColorC);
                    }
                    else {
                        ocultar(inputColorC);
                    }
                };
            }
       }
    };
} ();

if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = PublicidadForm;
}

