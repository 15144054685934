"use strict";

var SelectDependientes = function() {
    var Plugin = {
        cargaSelectDependiente: function(selectDependiente, url) {
            var entityId = null;
            var entityId2 = null;

            var depende = selectDependiente.closest('.select-dependiente').data('depende');
            if (depende) {
                var selectDepende = $(depende).find('select');
                entityId = selectDepende.val();
            }

            var depende2 = selectDependiente.closest('.select-dependiente').data('depende2');
            if (depende2) {
                var selectDepende2 = $(depende2).find('select');
                entityId2 = selectDepende2.val();
            }

            if (entityId) {
                var data = {
                    entityId: entityId,
                    entityId2: entityId2
                };

                $.ajax({
                    type: 'post',
                    url: url,
                    data: data,
                    success: function (data) {
                        selectDependiente
                            .find('option')
                            .not(':first')
                            .remove()
                            .end();

                        if (selectDependiente.find('option').first().val() > 0) {
                            selectDependiente.find('option').first().remove();
                        }

                        var total = data.length;

                        for (var i = 0; i < total; i++) {
                            //console.log(data[i].value+" - "+data[i].label);
                            selectDependiente.append('<option value="' + data[i].value + '">' + data[i].label + '</option>');
                        }

                        $('.select2entity').select2entity();
                    }
                });
            }
        }
    };

    return {
		init: function () {
			$(document).on('change', '.select-origen', function(e) {
                var $selectOrigen = $(this);
                var $infoOrigen = $selectOrigen.closest('.select-dependiente');
    
                if ($infoOrigen.data('dependiente')) {
                    var $selectDependiente = $($infoOrigen.data('dependiente')).find('select');
                    var $urlDependiente = $infoOrigen.data('dependiente-url');
    
                    if ($selectDependiente.hasClass('select-origen')) {
                        var $infoOrigenDependiente = $selectDependiente.closest('.select-dependiente');
    
                        if ($infoOrigenDependiente.data('dependiente')) {
                            var $selectDependienteDependiente = $($infoOrigenDependiente.data('dependiente')).find('select');
                            $selectDependienteDependiente.val(null);
                            $selectDependienteDependiente
                                .find('option')
                                .not(':first')
                                .remove()
                                .end();
                        }
                    }
    
                    Plugin.cargaSelectDependiente($selectDependiente, $urlDependiente);
                }
    
                if ($infoOrigen.data('dependiente2')) {
                    var $selectDependiente2 = $($infoOrigen.data('dependiente2')).find('select');
                    var $urlDependiente2 = $infoOrigen.data('dependiente2-url');
    
                    Plugin.cargaSelectDependiente($selectDependiente2, $urlDependiente2);
                }
            });
		}
	};
} ();

$(function() {
    SelectDependientes.init();
});