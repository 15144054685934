"use strict";

const Form = function() {
	const datePicker = function () {
		$('.js-datepicker').datepicker({
            locale: 'es'
        });
	}

	const datetimePicker = function () {
		$('.js-datetimepicker').datetimepicker({
			locale: 'es',
			showClear: true,
			showClose: true,
			keepOpen: false,
        });
	}

	const enterDisable = function () {
		$('.enter-disabled').bind('keypress keydown keyup', function(e) {
			if (e.keyCode == 13) { e.preventDefault(); }
		 });
	}

	const titleNoticia = function () {
		if ( $('#noticia_titular').length ) {
			$('#noticia_titular').css('height', document.getElementById("noticia_titular").scrollHeight + "px");
		}
	}

	const modalsNoticia = function () {
		$(".a-config-modal").click(function() {
			var aId = jQuery(this).attr("id");
			aId = aId.substring(1, aId.length);
			aId = "app" + aId;
			var modalsConfig = $(".modal-config");
			$(modalsConfig).each(function() {
				if($(this).attr("id") != aId) {
					$(this).modal('hide');
				}
			});
		});
	}

    return {
		init: function () {
			datePicker();
			datetimePicker();
			enterDisable();
			titleNoticia();
			modalsNoticia();
		}
	};
} ();

$(function() {
    Form.init();
});