"use strict";

var FormAjax = function() {
    var addOverlay = function(cont, el) {
        cont.addClass=('overlay overlay-block');
        el.addClass('overlay overlay-block');
        el.append('<div class="overlay-layer bg-dark-o-10"><div class="spinner spinner-primary"></div></div>');
    }

    var removeOverlay = function(cont, el) {
        cont.removeClass=('overlay overlay-block');
        el.removeClass('overlay overlay-block');
    }

    return {
		init: function () {
			$(document).on('submit', 'form.formajax', function(e) {
                var postData = new FormData(this); 
                var action = $(this).attr('action');
                var target = $(this).data('target');
                var redirect = $(this).data('redirect');
                var overlay = $(this).data('elem-overlay');

                if (overlay) {
                    addOverlay($(overlay), $(target));
                }

                $.ajax({
                    url : action,
                    type : "POST",
                    data : postData,
                    success : function(data)
                    {
                        if (data == "OK") {
                            if (redirect) {
                                window.location.replace(redirect);
                            } else {
                                window.parent.location.reload();
                            }
                        }
                        else {
                            
                            $('button[type="submit"]').removeClass('spinner spinner-white spinner-right spinner-sm');
                            $(target).html(data);

                            if (overlay) {
                                removeOverlay($(overlay), $(target));
                            }
                        }
                    },
                    cache: false,
                    contentType: false,
                    processData: false
                });
    
                e.preventDefault(); // STOP default action
            });
		}
	};
} ();

$(function() {
    FormAjax.init();
});