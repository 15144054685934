/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../scss/cms.scss';

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';
global.$ = global.jQuery = $;
global.moment = require('moment');

window.Cropper = require('./../../node_modules/cropperjs/dist/cropper');
/* import './components/yoastseo'; */

import 'bootstrap';
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.es';
window.Swal = require('sweetalert2/dist/sweetalert2');
import 'select2';
import 'select2/dist/js/i18n/es';
import '../../public/bundles/tetranzselect2entity/js/select2entity';
import Degradado from './components/degradado';
global.Degradado = Degradado;
import FormNoticia from './components/form-noticia';
global.FormNoticia = FormNoticia;
import Roles from './components/roles';
global.Roles = Roles;
import PublicidadForm from './components/publicidad-form';
global.PublicidadForm = PublicidadForm;
import GaleriaForm from './components/galeria-form';
global.GaleriaForm = GaleriaForm;
window.Dropzone = require('dropzone');
Dropzone.autoDiscover = false;

window.Popper = require('popper.js').default;
window.PerfectScrollbar = require('perfect-scrollbar/dist/perfect-scrollbar');

// Keenthemes' plugins
window.KTUtil = require('../metronic/js/components/util.js');
window.KTApp = require('../metronic/js/components/app.js');
window.KTCard = require('../metronic/js/components/card.js');
window.KTCookie = require('../metronic/js/components/cookie.js');
window.KTDialog = require('../metronic/js/components/dialog.js');
window.KTHeader = require('../metronic/js/components/header.js');
window.KTImageInput = require('../metronic/js/components/image-input.js');
window.KTMenu = require('../metronic/js/components/menu.js');
window.KTOffcanvas = require('../metronic/js/components/offcanvas.js');
window.KTScrolltop = require('../metronic/js/components/scrolltop.js');
window.KTToggle = require('../metronic/js/components/toggle.js');
window.KTWizard = require('../metronic/js/components/wizard.js');
require('../metronic/js/components/datatable/core.datatable.js');
require('../metronic/js/components/datatable/datatable.checkbox.js');
require('../metronic/js/components/datatable/datatable.rtl.js');

// Metronic layout base js
window.KTLayoutAside = require('../metronic/js/layout/base/aside.js');
window.KTLayoutAsideMenu = require('../metronic/js/layout/base/aside-menu.js');
window.KTLayoutContent = require('../metronic/js/layout/base/content.js');
window.KTLayoutFooter = require('../metronic/js/layout/base/footer.js');
window.KTLayoutHeader = require('../metronic/js/layout/base/header.js');
window.KTLayoutHeaderTopbar = require('../metronic/js/layout/base/header-topbar.js');
window.KTLayoutStickyCard = require('../metronic/js/layout/base/sticky-card.js');
window.KTLayoutStretchedCard = require('../metronic/js/layout/base/stretched-card.js');
window.KTLayoutSubheader = require('../metronic/js/layout/base/subheader.js');

// Metronic layout extended js
window.KTLayoutChat = require('../metronic/js/layout/extended/chat.js');
window.KTLayoutDemoPanel = require('../metronic/js/layout/extended/demo-panel.js');
window.KTLayoutExamples = require('../metronic/js/layout/extended/examples.js');
window.KTLayoutQuickActions = require('../metronic/js/layout/extended/quick-actions.js');
window.KTLayoutQuickCartPanel = require('../metronic/js/layout/extended/quick-cart.js');
window.KTLayoutQuickNotifications = require('../metronic/js/layout/extended/quick-notifications.js');
window.KTLayoutQuickPanel = require('../metronic/js/layout/extended/quick-panel.js');
window.KTLayoutQuickSearch = require('../metronic/js/layout/extended/quick-search.js');
window.KTLayoutQuickUser = require('../metronic/js/layout/extended/quick-user.js');
window.KTLayoutScrolltop = require('../metronic/js/layout/extended/scrolltop.js');
window.KTLayoutSearch = window.KTLayoutSearchInline = require('../metronic/js/layout/extended/search.js');

require('../metronic/js/layout/initialize.js');

require('tempusdominus-bootstrap-4/build/js/tempusdominus-bootstrap-4');
require('../metronic/js/vendors/plugins/tempusdominus-bootstrap-4.init');

require('bootstrap-notify/bootstrap-notify.js');

require('@shopify/draggable/lib/draggable.bundle.js');
require('@shopify/draggable/lib/draggable.bundle.legacy.js');
require('@shopify/draggable/lib/draggable.js');
window.Sortable = require('@shopify/draggable/lib/sortable.js');
require('@shopify/draggable/lib/droppable.js');
require('@shopify/draggable/lib/swappable.js');
require('@shopify/draggable/lib/plugins.js');
require('@shopify/draggable/lib/plugins/collidable.js');
require('@shopify/draggable/lib/plugins/resize-mirror.js');
require('@shopify/draggable/lib/plugins/snappable.js');
require('@shopify/draggable/lib/plugins/swap-animation.js');

require('./components/ckeditor');
// require('./components/draggable');
require('./components/form');
require('./components/form-ajax');
require('./components/link-remote');
require('./components/media');
window.Notify = require('./components/notify');
window.CmsOffcanvas = require('./components/offcanvas');
window.CmsPanel = require('./components/panel');
require('./components/portada');
require('./components/select-dependientes');
require('./components/settings');
require('./components/spinner-submit');

CmsPanel.init('cms_panel');
