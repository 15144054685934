"use strict";

var LinkRemote = function() {
    var addOverlay = function(el) {
        el.addClass('overlay overlay-block');
        el.append('<div class="overlay-layer bg-dark-o-10"><div class="spinner spinner-primary"></div></div>');
    }

    var removeOverlay = function(el) {
        el.removeClass('overlay overlay-block');
    }

    return {
		init: function () {
			$(document).on('click', '[data-load-remote]', function(e) {
                e.preventDefault();
    
                var $this = $(this);
                var target = $this.data('remote-target');
                var remote = $this.data('load-remote');
                var remove = $this.data('elem-remove');
                var overlay = $this.data('elem-overlay');
    
                if (remote) {
                    if (overlay) {
                        addOverlay($(overlay));
                    }
    
                    $(remove).remove();
    
                    $.get(remote, function (data) {
                        $(target).html(data);

                        if (overlay) {
                            removeOverlay($(overlay));
                        }
                    });
                }
            });
		}
	};
} ();

$(function() {
    LinkRemote.init();
});