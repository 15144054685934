"use strict";

const GaleriaForm = function() {
    const addFormToCollection = function($collectionHolderClass) {
        let $collectionHolder = $('.' + $collectionHolderClass);
        let prototype = $collectionHolder.data('prototype');
        let index = $collectionHolder.data('index');
        let newForm = prototype;
        
        newForm = newForm.replace(/__name__/g, index);

        $collectionHolder.data('index', index + 1);
        
        let $newFormLi = $('<li class="row"><div class="col-sm-1 acciones"></div><div class="col-sm-11 html-galeria"></div></li><br>');
        $newFormLi.find('.html-galeria').append(newForm); 
        $collectionHolder.append($newFormLi);
       
        addFormDeleteLink($newFormLi);
    }

    const addFormDeleteLink = function($formLi) {
       
        let $removeFormButton = $('<button type="button" class="btn btn-danger btn-sm mt-8"><i class="ki ki-bold-close icon-sm p-0"></i></button>');
        $formLi.find('.acciones').append($removeFormButton); 
        $removeFormButton.on('click', function(e) {
            $formLi.remove();
        });
    }

    return {
		init: function (clase) {
            let $collectionHolder = $(clase);
            $collectionHolder.data('index', $collectionHolder.find('li').length);
            
            $collectionHolder.find('li').each(function() {
                addFormDeleteLink($(this));
            });

            $('body').on('click', '.add_galeria_link', function(e) {
                var $collectionHolderClass = $(e.currentTarget).data('collectionHolderClass');
                addFormToCollection($collectionHolderClass);
            });
       }
    };
} ();

if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = GaleriaForm;
}
