"use strict";

var seleccionarMedia = function() {
    return {
		init: function () {
            $(document).on('click', '.seleccionar-media', function() {
                var input = '#'+$(this).data('input');
                var $input = $(input);
                var media = $(this).data('media');
                var asset = $(this).data('asset');
                var $image_input = $input.closest('.image-input');
                var $image = $input.siblings('.image-input-wrapper');
                $image_input.removeClass('image-input-empty');
                $input.val(media);
                $image.css('background-image', 'url(' + asset + ')');
            });
		}
	};
} ();

var eliminarBasicMedia = function() {
    return {
        init: function () {
            $(document).on('click', 'a[data-action="remove-basic"]', function(e) {
                console.log("remove!");
                e.preventDefault();

                var $image_input = $(this).closest('.image-input');
                var $input = $(this).siblings('input[type="hidden"]');
                var $image = $(this).siblings('.image-input-wrapper');
                $image_input.addClass('image-input-empty');
                $input.val(null);
                $image.css('background-image', 'url(/build/images/no_image.png)');
            });
        }
    };
} ();


var eliminarMedia = function() {
    return {
        init: function () {
            $(document).on('click', 'a[data-action="remove"]', function(e) {
                e.preventDefault();

                // Obtener los elementos relevantes
                var $image_input = $(this).closest('.image-input');
                var $input = $(this).siblings('input[type="hidden"]');
                var $image = $(this).siblings('.image-input-wrapper');

                $('#confirmModal').modal('show');

                // Al hacer clic en "Eliminar" en el modal
                $('#confirmDelete').off('click').on('click', function() {
                    // Eliminar la imagen seleccionada
                    $image_input.addClass('image-input-empty');
                    $input.val(null);
                    $image.css('background-image', 'url(/build/images/no_image.png)');

                    // Aplicar el cambio a todas las imágenes
                    $('.image-input').each(function() {
                        var $this_image_input = $(this);
                        var $this_input = $this_image_input.find('input[type="hidden"]');
                        var $this_image = $this_image_input.find('.image-input-wrapper');
                        $this_image_input.addClass('image-input-empty');
                        $this_input.val(null);
                        $this_image.css('background-image', 'url(/build/images/no_image.png)');
                    });

                    // Cerrar el modal
                    $('#confirmModal').modal('hide');
                });

                // Al hacer clic en "Cancelar" en el modal
                $('#confirmCancel').off('click').on('click', function() {
                    $image_input.addClass('image-input-empty');
                    $input.val(null);
                    $image.css('background-image', 'url(/build/images/no_image.png)');

                    // Cerrar el modal
                    $('#confirmModal').modal('hide');
                });

            });
        }
    };
}();

var refreshMedia = function() {
    return {
        init: function () {
            $(document).on('click', 'a[data-action="refresh"]', function(e) {
                e.preventDefault();

                // Obtener los elementos relevantes
                var $image = $(this).siblings('.image-input-wrapper');

                // Obtener la imagen actual
                var currentImage = $image.css('background-image');

                $('#refreshModal').modal('show');

                // Al hacer clic en "Actualizar" en el modal
                $('#confirmRefresh').off('click').on('click', function() {
                    // Actualizar todas las imágenes con la imagen actual
                    $('.image-input').each(function() {
                        var $this_image_input = $(this);
                        var $this_image = $this_image_input.find('.image-input-wrapper');
                        $this_image.css('background-image', currentImage);
                    });

                    // Cerrar el modal
                    $('#refreshModal').modal('hide');
                });

                // Al hacer clic en "Cancelar" en el modal
                $('#cancelRefresh').off('click').on('click', function() {
                    // Cerrar el modal
                    $('#refreshModal').modal('hide');
                });

            });
        }
    };
}();

var seleccionarMediaAudio = function() {
    return {
        init: function () {
            $(document).on('click', '.seleccionar-media-audio', function() {
                var input = '#'+$(this).data('input');
                var $input = $(input);
                var media = $(this).data('media');
                var asset = $(this).data('asset');

                var $audio_input = $input.closest('.audio-input');
                $audio_input.removeClass('audio-input-empty');

                $input.val(media);

                // var playerInstance = jwplayer("hyperplayer");
                // playerInstance.setup({
                //     file: asset,
                //     width: 640,
                //     height: 40,
                //     skin: {
                //         url: 'build/cms.css'
                //     }
                // });

                $("#hyperplayer")[0].src = asset;
            });
        }
    };
} ();

var eliminarMediaAudio = function() {
    return {
        init: function () {
            $(document).on('click', 'a[data-action="remove-audio"]', function(e) {
                console.log("remove audio!");
                e.preventDefault();

                var $input = $(this).siblings('input[type="hidden"]');
                var $audio_input = $(this).closest('.audio-input');
                $audio_input.addClass('audio-input-empty');
                $input.val(null);

                // var playerInstance = jwplayer("hyperplayer");
                // playerInstance.setup({
                //     file: "",
                //     width: 640,
                //     height: 40,
                //     skin: {
                //         url: 'build/cms.css'
                //     }
                // });

                $("#hyperplayer")[0].src = '';
            });
        }
    };
} ();

$(function() {
    seleccionarMedia.init();
    eliminarMedia.init();
    seleccionarMediaAudio.init();
    eliminarMediaAudio.init();
    refreshMedia.init();
    eliminarBasicMedia.init();
});