"use strict";

var SpinnerSubmit = function() {
    return {
		init: function () {
			var $buttonpressed;

            $(document).on('click', 'button', function() {
                $buttonpressed = $(this);
            });

            $(document).on('submit', 'form', function(e) {
                if ($buttonpressed.hasClass('spinner')) {
                    e.preventDefault();
                    return false;
                }

                if ($buttonpressed) {
                    $buttonpressed
                        .addClass('spinner spinner-white spinner-right spinner-sm');
                }

                return true;
            });
		}
	};
} ();

$(function() {
    SpinnerSubmit.init();
});