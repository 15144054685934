"use strict";

const Degradado = function() {

    var degradadoBoton = document.getElementById('degradadoBoton');
    var inputDegradadoBoton = document.getElementById('noticia_degradadoBoton');
    var textoDegradadoBoton = document.getElementById('textoDegradadoBoton');
    var inputDegradadoEnlace = document.getElementById('noticia_degradadoEnlace');
    var degradadoEnlace = document.getElementById('degradadoEnlace');
    var htmlDegradadoEnlace = document.getElementById('htmlDegradadoEnlace');

    var ocultar = function(el) {
        el.classList.add('d-none');
    }

    var mostrar = function(el) {
        el.classList.remove('d-none');
    }

    return {
		init: function () {

            if (inputDegradadoBoton.checked) {
                ocultar(degradadoEnlace);
                ocultar(htmlDegradadoEnlace);
                mostrar(textoDegradadoBoton);
            }
            else {
                mostrar(degradadoEnlace);
                ocultar(textoDegradadoBoton);
            }
            if (inputDegradadoEnlace.checked) {
                ocultar(degradadoBoton);
                ocultar(textoDegradadoBoton);
                mostrar(htmlDegradadoEnlace);
            }
            else {
                mostrar(degradadoBoton);
                ocultar(htmlDegradadoEnlace);
            }

            inputDegradadoBoton.onchange = function(){
                if (inputDegradadoBoton.checked) {
                    ocultar(degradadoEnlace);
                    ocultar(htmlDegradadoEnlace);
                    mostrar(textoDegradadoBoton);
                }
                else {
                    mostrar(degradadoEnlace);
                    ocultar(textoDegradadoBoton);
                }
            };

            inputDegradadoEnlace.onchange = function(){
                if (inputDegradadoEnlace.checked) {
                    ocultar(degradadoBoton);
                    ocultar(textoDegradadoBoton);
                    mostrar(htmlDegradadoEnlace);
                }
                else {
                    mostrar(degradadoBoton);
                    ocultar(htmlDegradadoEnlace);
                }
            };
		}
	};
} ();

if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = Degradado;
}