"use strict";
 
const Roles = function() {
    return {
        badgeCount: function() {
            let total = 0;
            $('tr.table-secondary').each(function () {
                const seccion = $(this).data('target').substring(1);
                const numMarcados = $('.'+seccion).find('input[type=checkbox]').filter(':checked').length;
                $(this).find('.bagde-count').text(numMarcados);
                total+= numMarcados;
            });
            $('#total').text(total);
        },

        actualizaBadgeCount: function() {
            $('input[type=checkbox]').on('change', function () {
                Roles.badgeCount();
            });
        },

        cargaRolesPerfil: function() {
            $('#usuario_perfil').on('change', function () {
                const path = $(this).data('path');
                const perfilId = $(this).val();
                
                if (perfilId) {
                    Roles.desmarcaTodo();

                    $.ajax({
                        url : path,
                        type : "GET",
                        data : { 'id': perfilId },
                        success : function(data) {
                            for (var i = 0; i < data.length; i++) { 
                                $('input[type=checkbox][value='+data[i]+']').prop('checked', true); 
                                Roles.badgeCount();
                            }
                        }
                    });

                    $(this).val(null);
                }
            });
        },

        desmarcaTodo: function() {
            $('input[type=checkbox]').prop('checked', false); 
        },

        cargaPermisos: function() {
            const tipo = $('#usuario_tipo').val();  
            if (tipo == 'ROLE_ADMIN'){                 
                $('.collapsebrandpublisher').eq(0).hide(); 
                $('.collapsebrandpublisher').eq(1).hide();
                $('.collapsebrandpublisher').eq(2).attr("style", "display:table-row");
            } else if (tipo == 'ROLE_BRANDPUBLISHER'){ 
                $('.collapsebrandpublisher').eq(0).attr("style", "display:table-row");
                $('.collapsebrandpublisher').eq(1).attr("style", "display:table-row");
                $('.collapsebrandpublisher').eq(2).hide(); 
                $('*[data-tipo="#tiporw"]').each(function () {
                    $(this).hide(); 
                }); 
            }
        },

        actualizaPermisos: function() {
            $('#usuario_tipo').on('change', function () { 
                const valor = $(this).val();  
                if (valor == 'ROLE_ADMIN'){ 
                    $('#usuario_roles_2').prop('checked', false);
                    $('.collapsebrandpublisher').eq(0).hide();                    
                    $('#usuario_roles_3').prop('checked', false);
                    $('.collapsebrandpublisher').eq(1).hide();                    
                    $('.collapsebrandpublisher').eq(2).attr("style", "display:table-row"); 
                    $('*[data-tipo="#tiporw"]').each(function () {
                        $(this).show();
                        $(this).attr("aria-expanded", "false");
                        $(this).addClass("collapsed"); 
                    }); 
                    Roles.desmarcaTodo();
                    Roles.badgeCount();
                } else if (valor == 'ROLE_BRANDPUBLISHER'){ 
                    $('.collapsebrandpublisher').eq(0).attr("style", "display:table-row");
                    $('.collapsebrandpublisher').eq(1).attr("style", "display:table-row");
                    $('#usuario_roles_4').prop('checked', false);
                    $('.collapsebrandpublisher').eq(2).hide(); 
                    $('*[data-tipo="#tiporw"]').each(function () {
                        $(this).hide(); 
                    });
                    $('.tiporw').each(function () {
                        $(this).removeClass("show"); 
                    }); 
                    Roles.desmarcaTodo();           
                    Roles.badgeCount();
                       
                }
                
            });
        },
	};
} ();

if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = Roles;
}